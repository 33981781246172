/* eslint-disable */

export const validPhoneCharacters = /^[+\d \s()\-,\.]+$/;

export const validEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validUKPhone = /^(?:\+?44|(?!44))(?:\s*\d){8,12}$/;
export const validROIPhone = /^(?:\+?353|(?!353))(?:\s*\d){8,12}$/;
export const validNIPostCode = /^BT([0-9]{1,2})[ ]{0,1}([0-9]{1}([a-zA-z]){2})$/i;
