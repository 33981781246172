import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import config from '../../../../app.config.json';
import axios, { API_URL } from '../services/axios';
import { LoquatePopulatedLookupItem } from '../types/loquate';

const AUTOFILL_URL = `${API_URL}/valuation/instant/find-address`;

export interface Option {
  label: string;
  value: any;
  highlights: string;
}

export const getFullAddress = async (loqateAddressCode: string): Promise<LoquatePopulatedLookupItem | null> => {
  try {
    const query = `?Key=${config.loquateKey}&Id=${loqateAddressCode}`;
    const result = await fetch(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws${query}`);

    const data = await result.json();

    return data.Items?.length ? data.Items[0] : null;
  } catch {
    return null;
  }
};

export const useLoqateWithFormik = (formik: any, address: string, postcode: string, loqateId: string) => {
  const setFormikValue = (field: string, value: string) => {
    formik.setFieldValue(field, value);

    // Fixes an issue within formik where using setFieldValue doesn't update validation
    // https://github.com/jaredpalmer/formik/issues/2059
    setTimeout(() => {
      if (postcode) formik.setFieldTouched(field, true);
    });
  };

  useEffect(() => {
    setFormikValue('postcode', postcode);
  }, [postcode]);

  useEffect(() => {
    setFormikValue('loqateId', loqateId);
  }, [loqateId]);

  useEffect(() => {
    // if there was a loqateId and the user wipes the input
    // reset the loqateId & postcode field
    if (loqateId && !address) {
      setFormikValue('loqateId', '');
      setFormikValue('postcode', '');
    }
  }, [address]);
};

const useLoqate = (address?: string, initialLoqateId?: string, initialPostcode?: string) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(address || '');
  const [postcode, setPostcode] = useState(initialPostcode || '');
  const [options, setOptions] = useState<Option[]>();
  const [loqateId, setLoqateId] = useState(initialLoqateId || '');

  const fetchResult = async (url: string) => {
    try {
      const result = await axios(
        {
          method: 'GET',
          url,
        },
        dispatch,
      );

      const newOptions = result.data.Items.map((item: any) => ({
        // add comma inbetween Text & Description, if Description exists
        label: `${item.Text}${item.Description ? `, ${item.Description}` : ''}`,
        value: item,
        highlights: item.Highlight,
      }));

      setOptions(newOptions);
    } catch (err: any) {
      // silent error
    }
  };

  const handleValueChange = (newValue: string) => {
    setValue(newValue);

    if (newValue) {
      const fullUrl = `${AUTOFILL_URL}?Text=${encodeURIComponent(newValue)}&Limit=10`;

      fetchResult(fullUrl);
    } else {
      setOptions(undefined);
    }
  };

  const handleOptionClick = (option: Option) => {
    if (option?.value.Type === 'Address') {
      const mPostcode = option.value.Description.split(',').slice(-1)[0];
      setValue(option.label);
      setLoqateId(option.value.Id);
      setPostcode(mPostcode.trim());
      return true;
    }

    const fullUrl = `${AUTOFILL_URL}?Limit=10&Container=${encodeURIComponent(option.value.Id)}`;
    fetchResult(fullUrl);
    return false;
  };

  return {
    postcode,
    loqateId,
    value,
    handleValueChange,
    handleOptionClick,
    options,
  };
};

export default useLoqate;
