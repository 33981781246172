import * as yup from 'yup';
import { validEmail, validPhoneCharacters, validNIPostCode } from '../utils/regex';

const valuationForm = {
  buildingNumber: yup.string().when(['loqateId', 'buildingName'], {
    is: (loqateId: string, buildingName: string) => !loqateId && !buildingName,
    then: (schema) => schema.required('Please enter a house number or house name'),
  }),
  buildingName: yup.string().when(['loqateId', 'buildingNumber'], {
    is: (loqateId: string, buildingNumber: string) => !loqateId && !buildingNumber,
    then: (schema) => schema.required('Please enter a house number or house name'),
  }),
  street: yup.string().when('loqateId', {
    is: (loqateId: string) => !loqateId,
    then: (schema) => schema.required('Please enter a street name'),
  }),
  town: yup.string().when('loqateId', {
    is: (loqateId: string) => !loqateId,
    then: (schema) => schema.required('Please enter a town or city'),
  }),
  postcode: yup
    .string()
    .required('Please enter a postcode')
    .matches(validNIPostCode, 'Please enter a valid Northern Ireland Postcode'),
  propertyType: yup.string().required('Please select a property type'),
  propertyStyle: yup.string().when('propertyType', {
    is: (value: string) => value === 'HOUSE' || value === 'BUNGALOW',
    then: (schema) => schema.required('Please select a property style'),
  }),
  bedrooms: yup.string().required('Please select the number of bedrooms'),
  relationship: yup.string().required('Please select your relationship to this property'),
  valuationReason: yup.string().when('mode', {
    is: 'agent',
    then: (schema) => schema.required('Please select your reason for getting a valuation'),
  }),
  timeframe: yup.string().when('valuationReason', {
    is: (value: string) => value === 'SELL' || value === 'LET',
    then: (schema) =>
      schema.when('relationship', {
        is: (value: string) => value !== 'TENANT',
        then: (mSchema) => mSchema.required('Please select a time frame'),
      }),
  }),
};

const sendRequest = {
  name: yup.string().required('Please enter your name'),
  email: yup
    .string()
    .matches(validEmail, 'Please enter a valid email address')
    .required('Please enter your email address'),
  phoneNumber: yup
    .string()
    .matches(validPhoneCharacters, 'Please enter a valid phone number')
    .required('Please enter your phone number'),
  contactPreference: yup.string().required('Please select a contact preference'),
};

export const valuationFormSchema = yup.object().shape(
  {
    ...valuationForm,
  },
  [['buildingNumber', 'buildingName']],
);

export const homepageValuationFormSchema = yup.object().shape({
  loqateId: yup.string().required('Please enter an address'),
  postcode: yup
    .string()
    .required('Please enter an address')
    .matches(validNIPostCode, 'Please enter a valid Northern Ireland Address'),
});

export const freeValuationSchema = yup.object().shape(sendRequest);

export const createAndSendSchema = yup.object().shape(
  {
    ...sendRequest,
    ...valuationForm,
  },
  [['buildingNumber', 'buildingName']],
);

export const PROPERTY_TYPES = [
  {
    label: 'House',
    value: 'HOUSE',
  },
  {
    label: 'Bungalow',
    value: 'BUNGALOW',
  },
  {
    label: 'Apartment',
    value: 'FLAT',
  },
];

export const HOUSE_STYLES = [
  {
    label: 'Detached',
    value: 'DETACHED_',
  },
  {
    label: 'Semi Detached',
    value: 'SEMI_DETACHED_',
  },
  {
    label: 'Town House',
    value: 'TOWNHOUSE_',
  },
  {
    label: 'End Terraced',
    value: 'END_TERRACED_',
  },
  {
    label: 'Mid Terraced',
    value: 'MID_TERRACED_',
  },
];

export const BUNGALOW_STYLES = HOUSE_STYLES.filter((style) => style.value !== 'TOWNHOUSE_');

export const BEDROOM_OPTIONS = [
  {
    label: '1 Bedroom',
    value: '1',
  },
  {
    label: '2 Bedrooms',
    value: '2',
  },
  {
    label: '3 Bedrooms',
    value: '3',
  },
  {
    label: '4 Bedrooms',
    value: '4',
  },
  {
    label: '5 Bedrooms',
    value: '5',
  },
  {
    label: '6+ Bedrooms',
    value: '6',
  },
];

export const RELATIONSHIP_OPTIONS = (mode: string) => [
  {
    label: "I'm the homeowner",
    value: 'HOMEOWNER',
  },
  {
    label: "I'm the tenant",
    value: 'TENANT',
  },
  {
    label: mode === 'agent' ? 'Other' : "I'm having a nosey",
    value: 'OTHER',
  },
];

export const CONTACT_OPTIONS = [
  {
    label: 'Email',
    value: 'EMAIL',
  },
  {
    label: 'Phone',
    value: 'PHONE',
  },
  {
    label: 'No preference',
    value: 'NO_PREFERENCE',
  },
];

export const CONTACT_TIME_OPTIONS = [
  {
    label: 'No preference',
    value: 'NO_PREFERENCE',
  },
  {
    label: 'Morning',
    value: 'MORNING',
  },
  {
    label: 'Afternoon',
    value: 'AFTERNOON',
  },
  {
    label: 'Evening',
    value: 'EVENING',
  },
];

export const REASON_OPTIONS = [
  {
    label: 'I want to sell the property',
    value: 'SELL',
  },
  {
    label: 'I want to let the property',
    value: 'LET',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const TENANT_REASON_OPTIONS = [
  {
    label: 'I want to buy the property',
    value: 'LET',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const WHEN_OPTIONS = [
  {
    label: '0 - 3 months',
    value: 'MONTHS_0_3',
  },
  {
    label: '3 - 6 months',
    value: 'MONTHS_3_6',
  },
  {
    label: '6+ months',
    value: 'MONTHS_6_PLUS',
  },
  {
    label: 'Unsure',
    value: 'UNSURE',
  },
];

export const emailValuationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(validEmail, 'Please enter a valid email address')
    .required('Please enter your email address'),
});

export const AGENT_FAQ_TEXT = [
  {
    title: 'How many estate agents should I request a valuation from?',
    text: [
      "It's generally recommended to request a valuation from at least three estate agents. This provides you with a range of opinions, helps you compare their services and allows you to make an informed decision when selecting the estate agent best suited to your needs and property.",
    ],
  },
  {
    title: "What's the difference between Instant Valuation and Agent Valuation?",
    text: [
      "Instant Valuation and Agent Valuation are two different approaches to estimating the value of a property. Instant Valuation uses a computer algorithm and extensive market data to quickly generate an estimated value for a property. However, it's important to note that there are certain factors the computer algorithm cannot fully take into account such as unique features, extensions and the property condition.",
      'While Instant Valuation can provide a helpful starting point, we always recommend an Agent Valuation. This is when an expert estate agent will conduct a thorough assessment of the property and its unique features, resulting in a more precise valuation that takes all factors into consideration.',
    ],
  },
  {
    title: 'Could the Instant Valuation estimated value be different from an Agent Valuation?',
    text: [
      "It is possible for our Instant Valuation estimate value to differ from an Agent Valuation due to various factors that are not accounted for by our computer. Factors such as the property's condition, unique features and changes in the neighbourhood can all impact the property's value. Additionally, renovations may also affect the property's estimated value which our algorithm may not be able to consider fully.",
      'By using an estate agent, they will have an in-depth knowledge of the local market and can provide a more accurate assessment of a property through an in-person valuation, taking into account all the specific details that make the property unique. While our Instant Valuation can give an estimate, it is important to keep in mind that an Agent Valuation will always provide a more precise valuation of the property.',
    ],
  },
  {
    title: 'Will my information remain confidential?',
    text: [
      'Absolutely, your information will remain safe and confidential with us. Should you decide to request an Agent Valuation, we will only share your contact details with the estate agents you specifically choose.',
    ],
  },
];

export const INSTANT_FAQ_TEXT = [
  {
    title: 'How does Instant Valuation work?',
    text: [
      'Using extensive market data including the property location and characteristics, our sophisticated computer algorithm will provide you with an estimated value of what the property could be worth.',
    ],
  },
  {
    title: 'How accurate is Instant Valuation?',
    text: [
      "The accuracy of Instant Valuation relies on the data available for the property. While it's a pretty nifty tool, keep in mind that it's from a computer. Along with the estimated value provided for the property, we will indicate the level of confidence in the estimate as low, medium or high. It's important to note that Instant Valuation does not reach the same level of accuracy as an in-person Agent Valuation. This is when an estate agent visits the property to conduct a more comprehensive and accurate valuation.",
    ],
  },
  {
    title: 'What information do I need to provide to use Instant Valuation?',
    text: [
      'Simply put in the address and a few other property details. You will instantly receive the estimated value on screen.',
    ],
  },
  {
    title: "What's the difference between Instant Valuation and Agent Valuation?",
    text: [
      "Instant Valuation and Agent Valuation are two different approaches to estimating the value of a property. Instant Valuation uses a computer algorithm and extensive market data to quickly generate an estimated value for a property. However, it's important to note that there are certain factors the computer algorithm cannot fully take into account such as unique features, extensions and the property condition.",
      'While Instant Valuation can provide a helpful starting point, we always recommend an Agent Valuation. This is when an expert estate agent will conduct a thorough assessment of the property and its unique features, resulting in a more precise valuation that takes all factors into consideration.',
    ],
  },
  {
    title: 'How much does Instant Valuation cost?',
    text: ["Absolutely nothing! It's 100% free to use."],
  },
  {
    title: 'How often should I use Instant Valuation?',
    text: [
      "We regularly update Instant Valuation, so use it as often as you like. It's important to remember that the estimate is from a computer and that market conditions and data can change frequently. To ensure you receive the most accurate valuation, we always recommend speaking to an estate agent.",
    ],
  },
  {
    title: 'Could the Instant Valuation estimated value be different from an Agent Valuation?',
    text: [
      "It is possible for our Instant Valuation estimate value to differ from an Agent Valuation due to various factors that are not accounted for by our computer. Factors such as the property's condition, unique features and changes in the neighbourhood can all impact the property's value. Additionally, renovations may also affect the property's estimated value which our algorithm may not be able to consider fully.",
      'By using an estate agent, they will have an in-depth knowledge of the local market and can provide a more accurate assessment of a property through an in-person valuation, taking into account all the specific details that make the property unique. While our Instant Valuation can give an estimate, it is important to keep in mind that an Agent Valuation will always provide a more precise valuation of the property.',
    ],
  },
  {
    title: 'Will my information remain confidential?',
    text: [
      'Absolutely, your information will remain safe and confidential with us. Should you decide to request an Agent Valuation, we will only share your contact details with the estate agents you specifically choose.',
    ],
  },
  {
    title: 'Why could Instant Valuation not provide me with an estimated value for a property?',
    text: [
      'While our Instant Valuation tool is designed to provide an estimated value, there are several reasons why it may not generate a value for a particular property. Insufficient data on the property or the surrounding area, such as a lack of recent property transactions or comparable properties in the area can limit the accuracy of the estimate. Additionally, unique features, high property values or if the property has been listed on PropertyPal within the past 6 months may pose challenges for the computer in generating an estimated value. To ensure you receive the most accurate valuation, we always recommend speaking to an estate agent.',
    ],
  },
  {
    title: "Why is the actual value of my property not within the estimated range from PropertyPal's computer?",
    text: [
      "It's possible for the actual value of a property to fall outside of the estimated value range. It could be higher or lower. For example, our algorithm may estimate a value range of £180k-£240k for a property that's valued at £360k. This can happen when a property has exceptional features or finishes, extensions or other unique attributes that our computer algorithm does not take into account. We always advise seeking a more accurate and personalised valuation from an estate agent who can provide you with a comprehensive analysis of the property's value.",
    ],
  },
];
