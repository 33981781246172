import useDebounce from '@propertypal/shared/src/hooks/useDebounce';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import React, { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Box } from '../layout';
import {
  Label,
  Input,
  Container,
  AutoCompleteBox,
  AutoCompleteItem,
  AutoCompleteContainer,
  Icon,
} from './TextInput.style';
import ValidationError from './ValidationError';

const DEBOUNCE_TIME = process?.env?.JEST_WORKER_ID ? 0 : 500;

export interface Option {
  label: string;
  value: any;
  highlights: string;
}

interface Props {
  label?: string;
  value: string;
  rounded?: boolean;
  onValueChange?: (value: string) => void;
  placeholder?: string;
  containerStyle?: CSSProperties;
  testID?: string;
  name: string;
  disabled?: boolean;
  error?: string | false;
  type?: string;
  autoCompleteOptions?: Option[];
  onOptionClick?: (option: Option) => boolean;
  autoCompleteText?: string;
  onAutoCompleteTextClick?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  icon?: any;
  borderColour?: string;
}

// highlights comes through via 2 ranges, split by `;`
// first range is for the Text value from loqate
// 2nd range is for the description value from loqate
const getHighlightedText = (text?: string, description?: string, highlights?: string) => {
  const highlightedText = [];
  let index = 0;

  // split the range for text and description strings from highlights range
  const [textHighlights, descHighlights] = (highlights || '').split(';');

  if (textHighlights && textHighlights.length > 0 && text)
    // convert string range to an array for text
    textHighlights.split(',').forEach((highlight) => {
      // get start and end index of range for text
      const startIndex = parseInt(highlight.split('-')[0], 10);
      const endIndex = parseInt(highlight.split('-')[1], 10);

      // push non highlighted text to main array that gets returned
      highlightedText.push(text.substring(index, startIndex));
      // push highlighted text with bold JSX to main array that gets returned
      highlightedText.push(<b key={`${startIndex}${text}`}>{text.substring(startIndex, endIndex)}</b>);
      index = endIndex;
    });

  if (text) highlightedText.push(text.substring(index, text.length));

  // push a comma & space characters before the description range if there is already text in main array
  if (description && highlightedText.length > 0) highlightedText.push(', ');

  let descIndex = 0;

  // possible that there is no description range
  if (descHighlights && descHighlights.length > 0 && description) {
    // convert string range to an array for description
    descHighlights.split(',').forEach((highlight) => {
      // get start and end index of range for description
      const startIndex = parseInt(highlight.split('-')[0], 10);
      const endIndex = parseInt(highlight.split('-')[1], 10);

      // push non highlighted text to main array that gets returned
      highlightedText.push(description.substring(descIndex, startIndex));
      // push highlighted text with bold JSX to main array that gets returned
      highlightedText.push(<b key={`${startIndex}${description}`}>{description.substring(startIndex, endIndex)}</b>);
      descIndex = endIndex;
    });
  }

  if (description) highlightedText.push(description.substring(descIndex, description.length));

  return highlightedText;
};

const TextInput: FunctionComponent<Props> = (props) => {
  const [val, setVal] = useState<string>(props.value);
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();

  const handleAutoCompleteClick = (option: Option) => {
    if (props.onOptionClick) {
      const removeFocus = props.onOptionClick(option);

      if (removeFocus) {
        setFocused(false);
      }
    }
  };

  const handleClickEvent = (e: MouseEvent) => {
    // @ts-ignore
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setFocused(false);
    }
  };

  useEventListener('click', handleClickEvent);

  const debounceHandler = useDebounce(
    (e) => {
      if (props.onValueChange) props.onValueChange(e);
    },
    props.autoCompleteOptions || props.autoCompleteText ? DEBOUNCE_TIME : 0,
    {
      leading: false,
      trailing: true,
    },
    [],
  );

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  return (
    <Container ref={containerRef} style={props.containerStyle}>
      {props.label && <Label htmlFor={props.name}>{props.label}</Label>}

      <Box display="flex" position="relative">
        {props.icon && (
          <Icon onClick={() => setFocused(true)} data-testid="inputIcon" type="button" tabIndex={-1}>
            <FontAwesomeIcon icon={props.icon} color={theme.backgroundMid} />
          </Icon>
        )}

        <Input
          disabled={props.disabled}
          id={props.name}
          name={props.name}
          data-testid={props.testID}
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
            if (props.onValueChange) {
              if (props.autoCompleteOptions || props.autoCompleteText) debounceHandler(e.target.value);
              else props.onValueChange(e.target.value);
            }
          }}
          placeholder={props.placeholder}
          error={props.error}
          type={props.type || 'text'}
          onFocus={() => {
            setFocused(true);
            if (props.onFocus) props.onFocus();
          }}
          onBlur={props.onBlur}
          icon={props.icon}
          rounded={props.rounded}
          borderColour={props.borderColour}
        />
      </Box>

      {focused && (props.autoCompleteOptions || props.autoCompleteText) && (
        <AutoCompleteContainer>
          <AutoCompleteBox>
            {props.autoCompleteOptions &&
              props.autoCompleteOptions.map((option, index) => (
                <AutoCompleteItem
                  key={option.label}
                  onClick={() => handleAutoCompleteClick(option)}
                  data-testid={`auto-complete-option-${index}`}
                  type="button"
                >
                  {/* use Text & Description for loquate, but use the label as a fallback */}
                  {getHighlightedText(
                    option.value.Text || option.label,
                    option.value.Description || '',
                    option.highlights,
                  )}
                </AutoCompleteItem>
              ))}

            {!!props.autoCompleteText && (
              <AutoCompleteItem onClick={props.onAutoCompleteTextClick}>{props.autoCompleteText}</AutoCompleteItem>
            )}
          </AutoCompleteBox>
        </AutoCompleteContainer>
      )}

      <ValidationError error={props.error} />
    </Container>
  );
};

export default TextInput;
